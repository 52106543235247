








































































































































import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";

import { searchUsers } from "@/utils/backend";

const SCHOOL_YEAR = "class";
const PROFESSIONAL_NETWORK = "profession";

const NAME = "name";
const CLASS = "class";
const ASC = true;
const DESC = false;

const searchTypes = [
  {
    value: SCHOOL_YEAR,
    text: "Search by Class",
  },
  {
    value: PROFESSIONAL_NETWORK,
    text: "Search by Profession",
  },
];

const sortTypes = [
  {
    value: NAME,
    text: "Sort by: Name with alphabatical order",
  },
  {
    value: `-${NAME}`,
    text: "Sort by: Name with alphabatical descending order",
  },
  {
    value: CLASS,
    text: "Sort by: Class ",
  },
  {
    value: `-${CLASS}`,
    text: "Sort by: Class descending order",
  },
];

interface SearchParams {
  type?: string;
  name?: string;
  year?: string;
  profession_id?: number;
}

export default Vue.extend({
  beforeRouteEnter(to, from, next) {
    next((vm: any) => {
      if (!isEmpty(to.query)) {
        vm.searchParams = to.query;
        vm.search();
      }
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (!isEmpty(to.query)) {
      this.searchParams = to.query;
      this.search();
    }
    next();
  },
  name: "Search",
  computed: {
    ...mapGetters(["professions", "user"]),
    searchParams: {
      get(): SearchParams {
        let result: SearchParams = {};

        if (this.searchType) {
          result.type = this.searchType;
        }
        if (this.searchName) {
          result.name = this.searchName;
        }
        if (this.searchYear) {
          result.year = "" + this.searchYear;
        }
        if (this.searchProfession) {
          result.profession_id = this.searchProfession;
        }

        return result;
      },
      set(value: any): void {
        if (value.type) {
          this.searchType = value.type;
        }
        if (value.name) {
          this.searchName = value.name;
        }
        if (value.year) {
          this.searchYear = "" + value.year;
        }
        if (value.profession_id) {
          this.searchProfession = parseInt(value.profession_id);
        }
      },
    },
    pagedResults(): Array<any> {
      if (this.pageSize === "All") return this.results;

      return this.sortedResults.slice(this.startIndex, this.endIndex);
    },
    startIndex(): number {
      if (this.pageSize === "All") return 0;
      return this.offset;
    },
    endIndex(): number {
      if (this.pageSize === "All") return 0;
      return this.startIndex + this.pageSize;
    },
    pageEnd(): number {
      return this.endIndex < this.results.length
        ? this.endIndex
        : this.results.length;
    },
    disablePrev(): boolean {
      if (this.pageSize === "All") return true;
      return this.startIndex === 0;
    },
    disableNext(): boolean {
      if (this.pageSize === "All") return true;
      return this.endIndex > this.results.length;
    },
    sortKey: {
      get(): string {
        return this.internalSortKey;
      },
      set(value: string): void {
        this.internalSortKey = value;
        if (!this.sortDirection) {
          this.sortDirection = !this.sortDirection;
        }
      },
    },
    sortDirection: {
      get(): boolean {
        return this.internalSortDirection;
      },
      set(value: boolean): void {
        this.internalSortDirection = value;
      },
    },
    sortedResults(): Array<any> {
      const results = [...this.results];
      results.sort((a, b) => {
        if (this.sortKey === NAME) {
          if (
            `${a.last_name_en} ${a.first_name_en}` >
            `${b.last_name_en} ${b.first_name_en}`
          ) {
            return this.sortDirection === ASC ? 1 : -1;
          } else if (
            `${a.last_name_en} ${a.first_name_en}` <
            `${b.last_name_en} ${b.first_name_en}`
          ) {
            return this.sortDirection === ASC ? -1 : 1;
          } else {
            return 0;
          }
        } else {
          if (a.class_year > b.class_year) {
            return this.sortDirection === ASC ? 1 : -1;
          } else if (a.class_year < b.class_year) {
            return this.sortDirection === ASC ? -1 : 1;
          } else {
            return 0;
          }
        }
      });
      return results;
    },
    mobileSortKey: {
      get(): string {
        return `${this.sortDirection ? "" : "-"}${this.sortKey}`;
      },
      set(v: string): void {
        if (v.startsWith("-")) {
          this.sortDirection = DESC;
        } else {
          this.sortDirection = ASC;
        }

        if (v.endsWith(NAME)) {
          this.internalSortKey = NAME;
        } else {
          this.internalSortKey = CLASS;
        }
      },
    },
  },
  data(): {
    tab: number;
    items: Array<any>;

    searchType: string;
    searchTypes: Array<any>;
    searchYear?: string;
    searchName?: string;
    searchProfession?: number;

    pageSize: number | "All";
    pageSizes: Array<any>;
    offset: number;

    searchClass: string;

    searchPressed: boolean;
    headers: Array<any>;
    results: Array<any>;

    internalSortKey: string;
    internalSortDirection: boolean;
    NAME: string;
    CLASS: string;
    sortTypes: Array<any>;
  } {
    return {
      tab: 0,
      items: [
        { tab: SCHOOL_YEAR, disabled: false },
        { tab: PROFESSIONAL_NETWORK, disabled: false },
      ],

      searchType: SCHOOL_YEAR,
      searchTypes,
      searchYear: undefined,
      searchName: undefined,
      searchProfession: undefined,

      pageSize: 20,
      pageSizes: [10, 20, 50, "All"],
      offset: 0,

      searchClass: "",

      searchPressed: false,
      headers: [
        { text: "First Name", value: "first_name_en" },
        { text: "Last Name", value: "last_name_en" },
        { text: "Email", value: "email" },
      ],
      results: new Array<any>(),

      internalSortKey: NAME,
      internalSortDirection: true,
      NAME,
      CLASS,
      sortTypes,
    };
  },
  mounted() {
    // this.searchYear = "" + this.user.class_year;
  },
  methods: {
    ...mapActions(["showLoading", "hideLoading"]),
    async goSearchClick() {
      const query = this.$route.query;

      if (isEqual(query, this.searchParams as any)) {
        await this.search();
      } else {
        this.$router.replace({
          query: this.searchParams as any,
        });
      }
    },
    async search(): Promise<void> {
      try {
        this.showLoading();

        const results = await searchUsers({
          ...(this.searchParams as any),
        });
        this.results = results;
        this.searchPressed = true;

        this.hideLoading();
      } catch (err) {
        console.error(err);
        this.hideLoading();
      }
    },
    getPageSizeLabel(input: any): string {
      if (input === "All") return "All";

      return `${input} results per page`;
    },
    clickResult(id: number | string): void {
      this.$router.push({
        name: "SearchResult",
        params: {
          id: `${id}`,
        },
      });
    },
    prevPage(): void {
      if (this.pageSize === "All") return;
      const result = parseInt("" + this.offset) - parseInt("" + this.pageSize);
      if (result >= 0) {
        this.offset = result;
      }
    },
    nextPage(): void {
      if (this.pageSize === "All") return;
      const result = parseInt("" + this.offset) + parseInt("" + this.pageSize);
      if (result <= this.results.length) {
        this.offset = result;
      }
    },
    headerClick(key: string): void {
      if (this.sortKey === key) {
        this.toggleSortDirection();
      } else {
        this.sortKey = key;
      }
    },
    toggleSortDirection(): void {
      this.sortDirection = !this.sortDirection;
    },
  },
});
